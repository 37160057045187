<template>
	<div class="card">
		<div class="card-content">
			<div class="content">
				<h3 class="title">{{jour.nom}}</h3>
				<p>{{jour.date}}</p>
				<p><b>{{presences.length}} personne(s) présente(s)</b></p>
				<p>
					<template v-for="(presence, index) in presences"><template v-if="index">,</template> {{presence.contenu.user}}</template>
				</p>
			</div>
		</div>
		<footer class="card-footer">
			<span class="card-footer-item">
				<template v-if="estPresent">
					<button @click="annuler" class="button is-warning" :class="{'is-loading':loading}">Annuler ma présence</button>
				</template>
				<template v-else>
					<button @click="present" class="button is-success" :class="{'is-loading':loading}">Déclarer ma présence</button>
				</template>
			</span>
		</footer>
	</div>
	
</template>

<script>
	export default {
		props : ['jour'],
		data() {
			return {
				loading:true,
				presences:[]
			}
		},
		mounted() {
			this.charger()
		},
		computed : {
			estPresent() {
				let present=false;
				this.presences.forEach(presence => {
					if(presence.identifiant == this.$store.state.user.id) {
						present=true;
					}
				});
				return present;
			}
		},
		methods : {
			charger() {
				let blob = {
					nature : 'presence',
					cle : this.jour.cle
				}
				this.api.post('get-blob',{blob}).then(response => {
					this.loading=false
					this.presences = response.data || [];
				});
			},
			annuler() {
				this.loading=true;
				let blob = {
					nature : 'presence',
					cle : this.jour.cle,
					identifiant : this.$store.state.user.id
				}
				this.api.post('delete-blob',{blob}).then(response => {
					this.charger()
				});				
			},
			present() {
				this.loading=true;
				let blob = {
					nature : 'presence',
					cle : this.jour.cle,
					identifiant : this.$store.state.user.id,
					contenu : {
						user_id:this.$store.state.user.id,
						user:this.$store.state.user.nom
					}
				};
				this.api.post('set-blob',{blob}).then(response => {
					this.charger()
				});				
			}
		}
	}
</script>